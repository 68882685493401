import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import useTranslation from 'shared/hooks/useTranslation';
import Carousel from 'react-multi-carousel';
import useIsInViewport from 'shared/hooks/useIsInViewport';
import { Section } from '../Styled';
import Item from './item';
import { CATEGORIES } from './testimonials';

const responsive = {
  desktop: {
    breakpoint: { min: 1050, max: 3000 },
    items: 1,
  },
  mobile: {
    breakpoint: { min: 0, max: 1049 },
    items: 1,
  },
};

const SectionWrapper = styled.div``;

const StyledSection = styled(Section)`
  max-width: 190rem;
  padding: 0;
  min-height: 80rem;
`;

const CarouselWrapper = styled.div<CarouselProps>`
  font-family: inherit;
  position: relative;
  display: ${({ isInView }) => (isInView ? 'block' : 'none')};

  .react-multi-carousel-dot-list {
    top: 5rem;
    z-index: 15;
    justify-self: center;
    height: 5rem;
    max-width: max-content;
    margin: 0 auto;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    text-transform: uppercase;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    justify-content: start;

    ${({ theme }) => theme.MEDIA.LG} {
      top: 9rem;
      justify-content: center;
    }

    ${({ theme }) => theme.MEDIA.LG} {
      top: 12rem;
    }
  }
`;

export const CustomDotElement = styled.li<DotsProps>`
  height: 3rem;
  width: 14rem;
  font-size: 1.9rem;
  line-height: 2.6rem;
  color: white;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
`;

type DotsProps = {
  isActive: boolean;
};

type CarouselProps = {
  isInView: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomDot = ({ onClick, ...rest }: any) => {
  const { t } = useTranslation('homePage');

  const {
    index,
    carouselState: { currentSlide },
  } = rest;

  return (
    <CustomDotElement
      onClick={() => {
        onClick();
      }}
      aria-hidden="true"
      isActive={index === currentSlide}
    >
      {t(CATEGORIES[index])}
    </CustomDotElement>
  );
};

function Testimonial(): JSX.Element {
  const testimonialRef = useRef(null);
  const testimonialInView = useIsInViewport(testimonialRef, {
    threshold: 0,
  });
  const [status, setStatus] = useState(false);
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (testimonialInView && !status) {
      setStatus(true);
    }
  }, [testimonialInView, status]);

  return (
    <StyledSection>
      <SectionWrapper ref={testimonialRef}>
        <CarouselWrapper isInView={status}>
          <Carousel
            afterChange={(previousSlide, { currentSlide }) => {
              setActive(currentSlide);
            }}
            responsive={responsive}
            containerClass="carousel-container"
            customDot={<CustomDot />}
            showDots
            swipeable
            draggable={false}
            ssr
            arrows={false}
            renderDotsOutside={false}
          >
            <Item itemNo={0} />
            <Item itemNo={1} isVisible={active} />
            <Item itemNo={2} isVisible={active} />
            <Item itemNo={3} isVisible={active} />
            <Item itemNo={4} isVisible={active} />
            <Item itemNo={5} isVisible={active} />
            <Item itemNo={6} isVisible={active} />
          </Carousel>
        </CarouselWrapper>
      </SectionWrapper>
    </StyledSection>
  );
}

export default Testimonial;
