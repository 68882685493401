import DOMPurify from 'isomorphic-dompurify';
import styled from 'styled-components';

import Image from 'next/image';
import useTranslation from 'shared/hooks/useTranslation';

import { VIDEOS } from './testimonials';

const SizeWrapper = styled.div`
  max-width: 74.7rem;
  width: 100%;
`;

const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  :before {
    content: '';
    height: 100%;
    width: 100%;
    background: rgb(0 0 0 / 60%);
    top: 0;
    position: absolute;
    left: 0;
    z-index: 10;
  }
`;

const Video = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;

  video {
    height: 112rem;
    width: auto;

    ${({ theme }) => theme.MEDIA.LG} {
      height: 98rem;
    }

    ${({ theme }) => theme.MEDIA.XXL} {
      height: 108rem;
    }
  }
`;

export const CustomDotElement = styled.li<DotsProps>`
  height: 3rem;
  width: 14rem;
  font-size: 1.9rem;
  line-height: 2.6rem;
  color: white;
  cursor: pointer;
  font-weight: ${({ isActive }) => (isActive ? '700' : '400')};
`;

const Container = styled.div`
  z-index: 12;
  position: absolute;
  top: 12rem;
  left: 0;
  right: 0;
  max-height: calc(100% - 12rem);
  max-width: 160rem;
  margin: 0 auto;
  display: grid;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding: 0 1.2rem;
  grid-template-rows: max-content 1fr;

  ${({ theme }) => theme.MEDIA.LG} {
    max-height: calc(100% - 23rem);
    top: 23rem;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    max-height: calc(100% - 26rem);
    top: 26rem;
  }
`;

const ReviewWrapper = styled.article`
  padding: 4.8rem 1.6rem 2.4rem 1.6rem;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 7.2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);

  ${({ theme }) => theme.MEDIA.LG} {
    padding: 5.5rem 2.6rem;
    max-width: 60rem;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    padding: 6.5rem 3.6rem;
    max-width: 66rem;
  }
`;

const Review = styled.p`
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.3rem;
  text-align: center;
  margin-bottom: 1rem;

  ${({ theme }) => theme.MEDIA.LG} {
    font-size: 1.5rem;
    line-height: 3.5rem;
    margin-bottom: 3.8rem;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    font-size: 2rem;
    line-height: 4rem;
    margin-bottom: 5.8rem;
  }
`;

const Author = styled.h3<ColorProps>`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.7rem;
  text-align: center;
  margin-bottom: 0.8rem;
  color: ${({ imgColor }) => imgColor || 'white'};

  ${({ theme }) => theme.MEDIA.LG} {
    font-size: 2.2rem;
    line-height: 2.7rem;
    text-align: left;
    margin-bottom: 1.2rem;
  }
`;

const AuthorPositon = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.3rem;
  text-align: center;

  ${({ theme }) => theme.MEDIA.LG} {
    font-size: 1.7rem;
    line-height: 2.1rem;
    text-align: left;
  }
`;

const Header = styled.h2<ColorProps>`
  font-size: 3rem;
  padding-top: 2rem;
  font-weight: 700;
  line-height: 3.6rem;
  text-align: center;
  margin-bottom: 2rem;

  span {
    color: ${({ imgColor }) => imgColor || 'white'};
  }

  ${({ theme }) => theme.MEDIA.LG} {
    font-size: 2.8rem;
    padding-top: 4rem;
    line-height: 3.8rem;
    margin-bottom: 2rem;
    text-align: left;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    font-size: 4.4rem;
    padding-top: 3rem;
    line-height: 5.5rem;
    margin-bottom: 2.8rem;
  }
`;

const Description = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.2rem;
  text-align: center;

  ${({ theme }) => theme.MEDIA.LG} {
    font-size: 1.8rem;
    line-height: 3.4rem;
    text-align: left;
    font-weight: 500;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    font-size: 2.4rem;
    line-height: 4rem;
  }
`;

const Logo = styled.div`
  margin: 0 auto;
  padding: 1rem 11%;
  height: 100%;
  display: flex;
`;

const ApostrofWrapper = styled.div<ColorProps>`
  position: absolute;
  top: 0.8rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 3.2rem;
  height: 3.2rem;
  color: ${({ imgColor }) => imgColor || 'white'};

  svg {
    height: 100%;
    width: auto;
  }

  ${({ theme }) => theme.MEDIA.LG} {
    top: -4rem;
    left: 1rem;
    width: 8rem;
    height: 8rem;
    right: unset;
  }
`;

const ContentWrapper = styled.div`
  color: white;
  gap: 2.2rem;
  display: flex;
  flex-direction: column-reverse;

  ${({ theme }) => theme.MEDIA.LG} {
    grid-template-columns: auto auto;
    gap: 5rem;
    display: grid;
    grid-template-rows: auto;
    padding: 0 1.2rem;
  }

  ${({ theme }) => theme.MEDIA.XXL} {
    gap: 7.2rem;
    padding: 0 1.6rem;
  }
`;

type ColorProps = {
  imgColor?: string;
};

type DotsProps = {
  isActive: boolean;
};

type ItemProps = {
  itemNo: number;
  isVisible?: number;
};

export default function Item({ itemNo = 0, isVisible = 0 }: ItemProps): JSX.Element {
  const { t } = useTranslation('homePage');
  const sanitizedData = (text: string) => ({
    __html: DOMPurify.sanitize(text),
  });

  return (
    <VideoWrapper key={VIDEOS[itemNo].id}>
      <Video>
        <video autoPlay loop muted poster={`/img/home/v2/testimonial/${VIDEOS[itemNo].link}.png`} playsInline>
          {itemNo === isVisible ? (
            <source src={`https://cdn.trustmate.io/video/${VIDEOS[itemNo].link}.webm`} type="video/webm" />
          ) : null}
          {itemNo === isVisible ? (
            <source src={`https://cdn.trustmate.io/video/${VIDEOS[itemNo].link}.mp4`} type="video/mp4" />
          ) : null}
        </video>
      </Video>
      <Container>
        <ContentWrapper>
          <ReviewWrapper>
            <ApostrofWrapper imgColor={VIDEOS[itemNo]?.color}>
              <svg width="81" height="81" viewBox="0 0 81 81" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M27.906 9.21417C25.8266 10.0046 22.5814 11.7411 20.2331 13.3196C17.4235 15.2082 13.5634 18.8101 11.641 21.337C8.18813 25.8756 6.3818 30.4917 5.34385 37.4306C4.64578 42.0965 4.59614 43.2811 4.53174 56.8242C4.46325 71.2275 4.40081 70.3235 5.54213 71.4646C6.64336 72.566 5.99185 72.5165 19.4055 72.5165C32.3609 72.5165 31.9073 72.5429 32.9776 71.7265C33.251 71.5181 33.6455 71.0568 33.8544 70.7016L34.2341 70.0556L34.2722 57.7839L34.3105 45.5123L34.0066 44.8059C33.6498 43.9767 32.6707 43.0525 31.9311 42.847C31.6279 42.7629 29.1015 42.7053 25.6912 42.7049L19.9607 42.704V41.8198C19.9607 39.8064 20.5347 36.3897 21.2533 34.1259C22.2086 31.1162 23.6662 28.6896 25.7775 26.5939C27.5 24.884 29.4946 23.5261 32.2986 22.1542C34.3489 21.1511 34.6564 20.9022 35.2198 19.7897C35.369 19.4951 35.4684 18.97 35.4733 18.451C35.4803 17.6996 35.3867 17.3945 34.6941 15.9121C32.0585 10.2715 31.8429 9.91364 30.742 9.35199C29.9347 8.9401 28.7753 8.88385 27.906 9.21417ZM69.4905 9.1111C68.7112 9.25496 65.0466 11.0482 63.0623 12.2567C57.0421 15.9233 52.3428 20.8354 49.5929 26.3362C47.5821 30.3585 46.5861 34.4218 45.8846 41.4651C45.6982 43.3368 45.6447 46.1354 45.5944 56.6824C45.5248 71.2439 45.4618 70.3218 46.6046 71.4646C47.7059 72.5659 47.0552 72.5165 60.4607 72.5165C73.5271 72.5165 73.0691 72.5449 74.1317 71.6691C74.4104 71.4393 74.8023 70.9224 75.0027 70.5203L75.3669 69.7894L75.3639 57.6179C75.3608 45.6768 75.3553 45.4342 75.0743 44.8134C74.7451 44.0859 74.2429 43.5194 73.5388 43.0809C73.058 42.7814 72.907 42.7726 67.0017 42.704L60.9568 42.6337L60.9997 41.5087C61.1107 38.603 61.8121 35.1449 62.7863 32.7019C63.788 30.1901 64.9877 28.3579 66.8082 26.5596C68.4651 24.923 70.1566 23.7568 72.8119 22.4208C75.3574 21.1402 76.0688 20.55 76.3569 19.4801C76.698 18.2137 76.5184 17.5757 74.84 14.0868C73.9033 12.1397 73.0721 10.6066 72.7515 10.2348C71.937 9.29025 70.745 8.87949 69.4905 9.1111Z"
                  fill="inherit"
                />
              </svg>
            </ApostrofWrapper>
            <Review>{t(VIDEOS[itemNo].clientReview)}</Review>
            <Author imgColor={VIDEOS[itemNo]?.color}>{VIDEOS[itemNo].clientName}</Author>
            <AuthorPositon>
              {VIDEOS[itemNo].clientPosition.includes('clientPosition')
                ? t(VIDEOS[itemNo].clientPosition)
                : VIDEOS[itemNo].clientPosition}
            </AuthorPositon>
          </ReviewWrapper>
          <SizeWrapper>
            <Header
              dangerouslySetInnerHTML={sanitizedData(t(VIDEOS[itemNo].header))}
              imgColor={VIDEOS[itemNo]?.color}
            />
            <Description>{t(VIDEOS[itemNo].description)}</Description>
          </SizeWrapper>
        </ContentWrapper>
        <Logo>
          <Image
            src={`/img/home/v2/logos/${VIDEOS[itemNo].logo}.svg`}
            alt=""
            width={VIDEOS[itemNo].logoSizeW}
            height={VIDEOS[itemNo].logoSizeH}
            style={{ maxWidth: '100%' }}
          />
        </Logo>
      </Container>
    </VideoWrapper>
  );
}
