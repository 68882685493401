export const VIDEOS = [
  {
    id: 1,
    link: 'home-page-sport',
    header: 'testimonials.sport.header',
    description: 'testimonials.sport.description',
    clientReview: 'testimonials.sport.clientReview',
    clientName: 'Tomasz Koźbiał',
    clientPosition: 'testimonials.sport.clientPosition',
    logo: '4f',
    logoSizeW: 202,
    logoSizeH: 80,
    color: '#E5D599',
  },
  {
    id: 2,
    link: 'home-page-casestudy-clothes',
    header: 'testimonials.clothes.header',
    description: 'testimonials.clothes.description',
    clientReview: 'testimonials.clothes.clientReview',
    clientName: 'Joanna Paleczny',
    clientPosition: 'Product Owner MODIVO',
    logo: 'modivo',
    color: '#B7CF9E',
    logoSizeW: 273,
    logoSizeH: 67,
  },
  {
    id: 3,
    link: 'home-page-casestudy-pharma',
    header: 'testimonials.pharma.header',
    description: 'testimonials.pharma.description',
    clientReview: 'testimonials.pharma.clientReview',
    clientName: 'Maciej Maćkowiak',
    clientPosition: 'Lead Product Manager Poland SUPER-PHARM',
    logo: 's-pharm',
    logoSizeW: 451,
    logoSizeH: 41,
    color: '#82BCDD',
  },
  {
    id: 4,
    link: 'home-page-casestudy-pets',
    header: 'testimonials.pets.header',
    description: 'testimonials.pets.description',
    clientReview: 'testimonials.pets.clientReview',
    clientName: 'Krzysztof Tatar',
    clientPosition: 'CEO tatardog.pl',
    logo: 'tatar-dog',
    logoSizeW: 140,
    logoSizeH: 140,
    color: '#FEC98F',
  },
  {
    id: 5,
    link: 'home-page-casestudy-shoes',
    header: 'testimonials.shoes.header',
    description: 'testimonials.shoes.description',
    clientReview: 'testimonials.shoes.clientReview',
    clientName: 'Joanna Paleczny',
    clientPosition: 'Product Owner E-OBUWIE',
    logo: 'eobuwie',
    logoSizeW: 394,
    logoSizeH: 82,
    color: '#FFF6A9',
  },
  // {
  //   id: 6,
  //   link: 'home-page-casestudy-beauty',
  //   header: 'Wzrost konwersji,setki tysięcy opinii o firmie i produktach',
  //   description: 'Podczas tej owocnej współpracy udało nam się wdrożyć
  // Nasze rozwiązania aż na 11 rynkach!',
  //   clientReview: 'Wartościowe opinie zebrane z pomocą TrustMate pomagają
  // zarówno naszym klientom w decyzjach zakupowych jak i nam. Dzięki informacji
  // zwrotnej i dialogu z użytkownikami możemy sprawniej reagować na ich
  // potrzeby. Jesteśmy jeszcze jeden krok bliżej klienta.',
  //   clientName: 'Alekandra Warzecha',
  //   clientPosition: 'Product Owner',
  //   logo: 'neonail',
  //   logoSizeW: 407,
  //   logoSizeH: 70,
  //   color: '#B7CF9E',
  // },
  {
    id: 7,
    link: 'home-page-casestudy-lifestyle',
    header: 'testimonials.lifestyle.header',
    description: 'testimonials.lifestyle.description',
    clientReview: 'testimonials.lifestyle.clientReview',
    clientName: 'Arkadiusz Kotula',
    clientPosition: 'E-commerce Manager NEW BALANCE',
    logo: 'nb',
    logoSizeW: 157,
    logoSizeH: 78,
    color: '#FF92E7',
  },
  {
    id: 8,
    link: 'home-page-casestudy-home',
    header: 'testimonials.home.header',
    description: 'testimonials.home.description',
    clientReview: 'testimonials.home.clientReview',
    clientName: 'Sebastian Szulawa',
    clientPosition: 'testimonials.home.clientPosition',
    logo: 'lozkoholicy',
    logoSizeW: 140,
    logoSizeH: 140,
    color: '#F4BA3E',
  },
];

export const CATEGORIES = [
  'testimonials.categories.sport',
  'testimonials.categories.fashion',
  'testimonials.categories.health',
  'testimonials.categories.pets',
  'testimonials.categories.footwear',
  //  'uroda',
  'testimonials.categories.lifestyle',
  'testimonials.categories.houseAndGarden',
];
