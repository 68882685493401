import styled from 'styled-components';

export const Heading = styled.header`
  color: #2e2e2e;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 4rem;
  text-align: center;

  ${({ theme }) => theme.MEDIA.MD} {
    font-size: 6.4rem;
    line-height: 7.8rem;
  }
`;

export const SubHeading = styled.h2`
  color: #637381;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 3rem;
  text-align: center;
  margin-top: 1.6rem;

  ${({ theme }) => theme.MEDIA.MD} {
    font-size: 2.6rem;
    line-height: 3rem;
    margin-top: 3rem;
  }
`;

export const Section = styled.section`
  max-width: 160rem;
  margin: 0 auto;
  font-family: ${({ theme }) => theme.FONTS.MAIN};
  padding: 0;

  @media ${({ theme }) => theme.DEVICE.XL} {
    padding: 3.2rem 1.6rem;
  }
`;

export const Header = styled.header`
  text-align: center;
  padding: 1.6rem 0;

  @media ${({ theme }) => theme.DEVICE.XL} {
    padding: 2.4rem 3.2rem;
  }
`;
